.upload-container {
    margin-left: 200px;
    margin-right: 200px;
    margin-top: 130px;
    height: 280px;
    background: azure;
}

.upload-escolher {
    font-weight: bold;
}

/* Media query para telas menores (ex: smartphones) */
@media (max-width: 600px) {
    .upload-container {
        margin-left: 20px;  
        margin-right: 20px;
        margin-top: 30px; 
        height: auto;  
        background: azure;
    }

    .upload-escolher {
        font-size: 1em;
    }
}
