.descriptionContainer {
    margin: 50px auto;
    max-width: 1000px;
    padding: 20px;
    border-radius: 8px;
    font-family: 'Arial', sans-serif;
}

.descriptionContainer h2 {
    color: #333;
    margin-bottom: 10px;
}

.descriptionContainer h3 {
    color: #555;
    margin-bottom: 8px;
}

.descriptionContainer p {
    color: #666;
    line-height: 1.6;
    text-align: justify;
    margin-bottom: 15px;
}

/* Media query para telas menores (ex: tablets e smartphones) */
@media (max-width: 768px) {
    .descriptionContainer {
        margin: 10px; 
        padding: 15px; 
        border-radius: 5px; 
    }

    .descriptionContainer h2 {
        font-size: 1.5em; 
        margin-bottom: 8px;
    }

    .descriptionContainer h3 {
        font-size: 1.3em; 
        margin-bottom: 6px;
    }

    .descriptionContainer p {
        font-size: 1em;
        text-align: left; 
        margin-bottom: 10px;
    }
}
