.title {
    text-align: center;
    font-size: 2.5em; 
    color: #000000;
    font-weight: 700;
    padding: 5px;
    margin-top: 80px;
    text-shadow: 2px 2px 4px rgb(199, 181, 181);
}

/* Media query para telas menores (ex: smartphones) */
@media (max-width: 600px) {
    .title {
        font-size: 1.5em; 
        margin-top: 40px; 
    }
}
