@media (max-width: 600px) {
    .card-container {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 30px;
        width: calc(100% - 40px);
    }

    .card-container .ant-card-body {
        font-size: 0.8em;
    padding: 10px;
    }
}